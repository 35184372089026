import React, { ReactElement, useRef } from 'react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { breakpoints, dimensions } from '../../styles/variables';
import { Feature } from "../Feature/Feature";
import { FeatureParagraphCrown, FeatureParagraphMark, FeatureTitleSwirl,} from "../../styles/styles";
import Divider from '../../assets/divider.inline.svg';
import { useLocation } from '@reach/router';

interface ZizzlingBonusesProps {
 
  noScribbles: boolean; 
}

export const ZizzlingBonuses = ({ noScribbles }: ZizzlingBonusesProps): ReactElement => {
  const ZizzlingBonusesRef = useRef(null);
  const { isVisible } = useVisibilityDetection(ZizzlingBonusesRef);
  const location = useLocation(); 
  const showAll = true; //(location.pathname != '/sale/' && location.pathname != '/sale') 
  //console.log(showAll)
    return (
      <Styled.SafariWrapper>
        <Styled.Wrapper ref={ZizzlingBonusesRef} isVisible={isVisible}>
          <Divider/>
          
       { showAll &&
      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 50px;
          padding: 0px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.xl}px) {
            padding:0 30px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
           
          }

          
        `}
        heading={
          <Styled.Heading  css={css`
                                width: 80%;
                                margin: auto;
                                @media screen and (max-width: ${breakpoints.md}px) {
                                  width: 100%;
                                }
                              `}>
            <Styled.HeadingTitle>PLUS, When You Join SketchWow Today, You'll Get These Sizzling Bonuses!</Styled.HeadingTitle>
          </Styled.Heading>
        }

        leftColumn={
          <Styled.LeftColumn css={css`
          padding: 0px;
          /*margin-left: -100px;
          width: 80%;
          @media screen and (min-width: 1301px) and (max-width: 1360px) {
            margin-left: -60px;
            width:90%;
          }
          @media screen and (min-width: 1280px) and (max-width: 1300px) {
            margin-left: -50px;
            width:90%;
          }
          @media screen and (max-width: 1279px) {
            margin-left: 0px;
            width:90%;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            width:100%;
            text-align:center;
          }*/

        `}>
            
            <Styled.Content>
              <Styled.ContentHeading>
              <img src="/salespage/zizzling bonus/bonus one.svg"/>
                <div>
                <Styled.SubTitle>Presentation Annotator </Styled.SubTitle>
                <Styled.ContentSubTitle>$39 Value</Styled.ContentSubTitle>
                </div>
                
              </Styled.ContentHeading>
              
              
              <p>
                Are you creating presentations, recording your screen, hosting virtual or Zoom meetings... or do you plan to at some point? If so, you're gonna love this bonus!
              </p>
              <p>
               Rather than conduct snooze-ville presentations, your SketchWow software includes a brand new annotation tool. This makes your presentations sizzle and boosts engagement and audience participation. How? 
              </p>
              <p>
                Picture this... with a few mouse clicks you can draw on the screen, add text/comments, click a Laser Pointer or Spotlight to highlight or zoom-in areas on your SketchWow slides. Also, you can "edit" you presentation in real time - move things around.
              </p>
              <p>
                That's right.. no more typical static-slides. Imagine annotating in real-time in SketchWow and creating dynamic, interactive presentations that dazzle your audience! Plus, you can choose to save all your slide annotations too.
                
              </p>

            </Styled.Content>
            
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>


            <Styled.Illustration css={css`
                @media screen and (max-width: ${breakpoints.xl}px) {
                  max-width:100%;
                }   
                @media screen and (max-width: ${breakpoints.lg}px) {
                  margin-top:30px;
                }
              `}
                src= "/salespage/zizzling bonus/presentation-annotator.png"
                alt="Illustration"
                loading="lazy"
                width={650}
              />
          </Styled.RightColumn>
        }
      />
    }
 

      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 0px;
          padding: 0px;

          /*opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
            padding:0 30px;
          }

          
        `}
 
        leftColumn={
          <Styled.LeftColumn css={css`
          padding: 0px;
          margin-left: -100px;
          @media screen and (min-width: 1301px) and (max-width: 1360px) {
            margin-left: -60px;
          }
          @media screen and (min-width: 1280px) and (max-width: 1300px) {
            margin-left: -50px;
          }
          @media screen and (max-width: 1279px) {
            margin-left: 0px;
          }
          @media screen and (max-width: ${breakpoints.lg}px) {
            margin-top: 40px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            text-align:center;
          }
        `}>
            
              <Styled.Illustration css={css`
          @media screen and (max-width: ${breakpoints.xl}px) {
            max-width:100%;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            max-width:80%;
          }
        `}
                src="/salespage/zizzling bonus/sizzling-scribbles.svg"
                alt="Illustration"
                loading="lazy"
              />
            
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Content>
              <Styled.ContentHeading>
              <img src="/salespage/zizzling bonus/bonus two.svg"/>
                <div>
                  <Styled.SubTitle>Sizzling Scribbles </Styled.SubTitle>
                  <Styled.ContentSubTitle>$19 Value</Styled.ContentSubTitle>
                </div>
                
                </Styled.ContentHeading>
              
              <p>
                Want to add even more sizzle to your sketches? We got you! Our talented design team added this collection of fun, hand-drawn, sizzling scribbles to SketchWow.
              </p>
              <p>
               Add scribbles to your photos, images or any design and make it really POP. If you are looking to add a playful, not-so-serious look that instantly injects personality into your visuals, these scribbles are perfect to have in your pocket.
              </p>
              <p>
                You'll get instant access to over 50 customizable scribbles. Note: our design team had so much fun making these, more are being added soon. Yup, you get access to all future scribbles too!
                
              </p>

            </Styled.Content>
          </Styled.RightColumn>
        }
      />

{!noScribbles && <Styled.PenScribble/>}
 
        </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};











